/* Gallery */
.gallery {
  width: auto;
  font: 19px Arial;
  margin-left: auto;
  margin-right: auto;
}

.gallery table,
th,
td {
  max-width: 400px;
  border: none;
  border-collapse: collapse;
  padding: 3px 4px;
  text-align: center;
  overflow-x: auto;
}

.gallery img {
  padding: 0vw;
  width: 300px;
}
.view {
  padding: 8px 36px;
  justify-self: center;
  align-self: center;
  font-family: "Poppins";
  font-weight: 600;
  margin-top: 20px;
  font-size: 15px;
  background: #ffd500;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.432);
  margin-bottom: 20px;
  transition: 0.5s ease-out;
}
.view:hover {
  background: #eae714;
  box-shadow: 2px 2px 8px 3px rgba(255, 251, 36, 0.538);

  transition: 0.5s ease-out;
}

/* mobile */
@media screen and (max-width: 1024px) and (min-width: 200px) {
  .gallery table,
  th,
  td {
    max-width: 200px;
    border: none;
    border-collapse: collapse;
    padding: 3px 4px;
    text-align: center;
    overflow-x: auto;
  }

  .gallery img {
    padding: 0vw;
    width: 28.5vw;
  }
  .view {
    padding: 8px 14px;
    font-size: 12px;
    width: 140px;
    text-align: center;
  }
}
