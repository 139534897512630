.addteam-container {
  background: linear-gradient(180deg, #930c0e 0%, #3d0a0b 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.addteam-container h3 {
  margin: 0px auto 30px;
  color: #d0d0d0;
}

.addteam-container .invite_card {
  margin: 10px 20px;
  padding: 20px;
  box-sizing: border-box;
  height: fit-content;
  min-height: 100px;
  width: 320px;
  border-radius: 10px;
  background: linear-gradient(120deg, #fff 0%, #e7cf5b 100%);
  box-shadow: 0px 0px 10px 5px #00000030;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.addteam-container .invite_card .element p span {
  font-weight: 600;
}

.teamlist {
  display: flex;
  flex-direction: row;
  max-width: 80%;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 0px;
  line-height: 1.5;
}
p.status {
  width: fit-content;
  padding: 5px 20px;
  font-weight: 600;
  box-shadow: 0px 0px 10px 5px #00000030;
  font-family: "Lato";
  letter-spacing: 0.5px;

  color: white;
}
p.decline {
  background: rgb(98, 17, 17);
}
p.accepted {
  background: rgb(7, 108, 224);
}
p.pending {
  background: rgb(73, 73, 73);
}

@media (max-width: 800px) {
  .teamlist {
    max-width: 90%;
  }
  .addteam-container .invite_card {
    width: 90%;
    height: 120px;
  }
}
