.event_card {
  margin: 10px 20px;
  box-sizing: border-box;
  height: 420px;
  min-width: 300px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background: linear-gradient(180deg, #fff 0%, #ffdb2a 100%);
  box-shadow: -5px 5px 40px 0px #b2dae5 inset;
  position: relative;
  max-width: 300px;
}

.event_card .tag {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 100;
  background: #ffd500;
  color: #2f2f2f;
  font-weight: 600;
  font-family: "Poppins";
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 2px 10px 2px 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.event_card .tag .i {
  font-size: 15px;
  padding-right: 2px;
}
.event_card .event_image {
  position: relative;
  width: 100%;
  height: 200px;
}
.event_card .event_image img {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 10px 10px 120px 120px;
}

.event_card .category {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #e51b20;
  color: white;
  font-size: 14px;
  width: wrap-content;
  padding: 10px 15px;
  width: 120px;
  text-align: center;

  margin: 0 auto;
  margin-top: -15px;
}
.event_card h3 {
  text-align: center;
  font-weight: 600;
  color: #323238;
  padding: 15px;
}
.event_card .date_n_time {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.event_card .date_n_time .detail {
  padding: 0px 10px;
}
.event_card .detail {
  text-align: center;
  color: #2f2f2f;
  font-size: 14px;
}

.event_card .btn {
  outline: none;
  border: 0px;
  background: #fff;
  /* width: calc(100% - 40px); */
  padding: 10px 15px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  font-size: 12px;
  text-decoration: none;
  color: #333333;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  position: absolute;
  bottom: 16px;
  left: 20px;
  right: 20px;
  text-align: center;
}

.event_card .btn:hover {
  background: #fff;
  box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.25);
}
