.register-form-container {
  /* background: linear-gradient(180deg, #5d0809 5.9%, #200304 100%); */
  background: linear-gradient(180deg, #f3d465 0%, #b3292b 40.18%, #4f0a0b 100%);

  min-height: calc(100vh - 60px);
}
.error-message {
  background-color: #de4648;
  color: white;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 5px;
}
