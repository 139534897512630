.event_register_container {
  background: linear-gradient(180deg, #5d0809 5.9%, #200304 100%);
  padding: 40px;
  display: flex;
  flex-direction: column;
}
h1 {
  font-family: "Marcellus SC";
  color: #ffd500;
}
.register_form {
  background-color: rgb(255, 226, 133);
  padding: 20px;
  margin: 0px auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.invite_form {
  background-color: white;
  padding: 0px 20px;
  margin: 10px auto;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
}
.invites {
  flex-direction: column;
  width: 60%;
}
.invites .label-group {
  max-width: 100%;
}

.invites input {
  width: 100%;
}
.register_details {
  padding: 20px;
  margin: 0px 0px 40px 0px;
  color: #242424;
  background-color: white;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.25);
}

.register_details h2 {
  font-size: 32px;
  color: #8f8f8f;
}
.other_details {
  display: flex;
  flex-direction: row;
  margin-top: 60px;
}
.other_details p {
  font-size: 16px;
  margin-right: 30px;
}
.other_details p span {
  font-weight: 600;
}
.register_form .button {
  cursor: pointer;
  outline: none;
  border: 0px;
  background: #0066ff;
  margin: 20px auto;
  padding: 10px 40px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
}
.invite_form .button,
.add_button {
  height: 40px;
  background: #e5ba1f;
  padding: 5px 20px;
  letter-spacing: 0px;
  font-weight: 600;
  text-transform: capitalize;
  color: #fff;
  align-self: flex-end;
  margin-bottom: 15px;
  cursor: pointer;
  outline: none;
  border: 0px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
}
.invite_form .button {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
}
.add_button {
  align-self: center;
  color: #5d0809;
  margin-top: 40px;
  font-size: 16px;
}

.invite_form .button .i {
  padding-right: 5px;
}
.drop_box {
  margin: 10px 0;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px dotted #5d0809;
  border-radius: 5px;
}

.drop_box h4 {
  font-size: 16px;
  font-weight: 400;
  color: #2e2e2e;
}

.drop_box p {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #3b3b3b;
}

.btn {
  text-decoration: none;
  background-color: #f00000;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  outline: none;
  transition: 0.3s;
}

.btn:hover {
  text-decoration: none;
  background-color: #b10a18;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  box-shadow: 0px 4px 10px 4px rgb(255, 247, 23);
}
.form input {
  margin: 10px 0;
  width: 100%;
  background-color: #e2e2e2;
  border: none;
  outline: none;
  padding: 12px 20px;
  border-radius: 4px;
}

@media (max-width: 800px) {
  .event_register_container {
    padding: 20px;
  }

  .register_form,
  .invite_form {
    margin: 0 auto;
    width: 80%;
  }
}

@media (max-width: 500px) {
  .event_register_container {
    padding: 10px;
  }
  .register_form,
  .invite_form {
    margin: 0 auto;
    width: 100%;
  }
}
