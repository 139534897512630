/* .events_main_container {
  background-color: #f0f0f0;
  color: #1e1e1e;
}

.event_detail_container {
  background-color: white;
  margin: 40px 20%;
}
.event_detail_container .event_image {
  position: relative;
  height: 420px;
  width: 250px;
}
.event_detail_container .event_image img {
  position: absolute;
  width: 100%;
  height: 100%;
} */

.events_main_container {
  background: linear-gradient(180deg, #930c0e 0%, #3d0a0b 100%);
  min-height: 100vh;
  padding: 50px 0px;
}
.event_detail_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 40vh;
  background-color: #f0f0f0;
  color: #1e1e1e;
  box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.25);
  margin: 0px 8%;
}

.event_detail_container .event_image {
  position: relative;
  width: 40%;
  height: 100%;
  overflow: hidden;
}

.event_detail_container .event_image img {
  width: auto;
  height: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.event-text-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  position: relative;
}
.event-text-container h2 {
  margin: 0px 0px 20px 0px;
}

.event-text-container .details {
  display: flex;
  flex-direction: row;
  padding: 8px 10px;
}
.event-text-container .details .i {
  font-size: 18px;
}
.event-text-container p {
  font-size: 14px;
  padding-left: 20px;
}
.event-text-container .description {
  font-size: 16px;
  padding: 10px 0px;
}

.event-text-container .other_details {
  display: flex;
  flex-direction: row;
  margin: 20px 0px 0px 0px;
}
.event-text-container .other_details .det {
  padding: 0px 50px 0px 0px;
}
.event-text-container .other_details .det p {
  padding: 0px;
}
.det_title {
  font-weight: 600;
  text-align: start;
}
.top-text {
  position: relative;
}
.event-text-container .event_button {
  position: absolute;
  top: 0px;
  right: 0px;
  outline: none;
  border: 0px;
  background: linear-gradient(180deg, #5d0809 0%, #730a0b 100%);
  width: wrap-content;
  padding: 10px 20px;
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.25);
}
.event-text-container .event_button a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.event_desc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #f0f0f0;
  padding: 0px;
  height: fit-content;
  margin: 10px 8%;
  background: linear-gradient(
    103deg,
    #f0da58 27.94%,
    #c5b981 87.37%,
    #e1c01e 100.03%
  );
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.25);
}
.event_desc .part {
  width: 48%;
  padding: 20px 10px;
}

.event_desc .part ul {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 0px;
  list-style-type: disc;
}
.event_desc .part ul li {
  list-style-type: disc;
  padding: 0px;
  margin: 5px 0px 0px 0px;
}

@media (max-width: 700px) {
  .event_detail_container {
    flex-direction: column;
    height: fit-content;
    position: relative;
    padding: 10px;
    margin: 0px 2%;
  }
  .event_desc {
    position: relative;
    padding: 10px;
    margin: 0px 2%;
    flex-wrap: wrap;
  }
  .event_detail_container .event_image {
    width: 100%; /* Make the image span the full width */
    height: 50%; /* Set the height to half of the container */
  }
  .event_detail_container .event_image img {
    width: 100%; /* Ensure the image fills the entire width */
    height: 100%; /* Maintain the aspect ratio and fill the height */
    object-fit: cover; /* Maintain aspect ratio and cover the container */
  }
  .event-text-container {
    width: 100%; /* Make the text container span the full width */
  }
  .event_desc .part {
    width: 100%;
  }
  .top-text {
    position: default;
  }
  .event-text-container .event_button {
    position: default;
    top: -60px;
    right: 0px;
    left: 0px;
    outline: none;
    border: 0px;
    width: wrap-content;
    padding: 10px 20px;
    margin: 10px 60px;
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.25);
  }
  .event-text-container .event_button a {
    text-decoration: none;
    color: #ffffff;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }
}
