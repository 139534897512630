* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  font-family: "Lato", sans-serif;
}

h1 {
  text-align: center;
  padding: 40px;
  font-family: "Marcellus SC";
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #333;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d6b80e;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e67b0f;
}
