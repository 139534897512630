ul {
  list-style-type: none;
  display: flex;
}
li a {
  text-decoration: none;
  color: inherit;
  font-family: Marcellus SC;
  font-weight: 600;
}
li a:hover {
  color: #ffffff;
}
nav {
  box-shadow: 4px 4px 8px rgb(0, 0, 0, 0.2);
  height: 72px;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #1d1d1d;
}
.logo {
  width: 148px;
  margin-left: 20px;
}
.logo img {
  width: 100%;
}
.nav-items {
  margin: 0 auto;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 1px;
}
li {
  padding: 20px;
}
.nav_container {
  margin: -8px auto;
  max-width: 1100px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.toggle-btn {
  display: none;
  position: absolute;
  top: 1.5rem;
  cursor: pointer;
  right: 1.5rem;
  flex-direction: column;
  justify-content: space-between;
}
.toggle-btn .i {
  font-size: 28px;
  color: rgb(255, 179, 0);
}

@media (max-width: 700px) {
  .nav_container {
    flex-direction: column;
    align-items: center;
  }
  .toggle-btn {
    display: block;
  }
  .nav-items {
    display: none;
  }

  .nav-items ul {
    flex-direction: column;
    background: #1d1d1d;
  }
  .nav-items ul li {
    width: 100vw;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    margin: 0 auto;
    text-align: center;
  }
  .nav-items.active {
    display: flex;
    color: #ffffff;
  }
}
