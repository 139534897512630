

/* new */
footer{
  height: 65vh;
  position: relative;
  text-align: center;
  font-family: "Lato";
  padding-top: 70px;
}
footer .content {
  padding: 40px 0px 40px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: top;
}
#footerhr{
      height: 2px;
      width:97%;
      border-width: 0;
      color: red;
      background-color: rgb(112, 21, 21);
}
.footer-logo {
  width: 300px;
}
.social-logo {
  width: 40px;
  height: 40px;
  margin: 0px 10px 0px 10px;
}

footer h1 {
  font-family: "Lato";
  font-size: 20px;
}

.connect {
  left: -100px;
}

.bottom h1 {
  padding-bottom: 20px;
}

.bottom h2 {
  font-size: 20px;
}
a:link {
  color: rgb(176, 4, 6);
}

a:visited {
  color: rgb(176, 4, 6);
}
a:link, a:visited {
  text-decoration: none;
}

@media screen and (max-width:1024px) and (min-width:200px){

@media screen and (max-width: 768px) {
 
  footer {
    height: auto; 
  }

  footer .content {
    flex-direction: column; 
    align-items: center; 
  }

  .footer-logo {
    width: 200px;
  }

  .social-logo {
    width: 30px; 
    height: 30px;
  }

}

}
