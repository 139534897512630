.myevents-container {
  background: linear-gradient(180deg, #f3d465 0%, #b3292b 40.18%, #4f0a0b 100%);
  min-height: calc(100vh - 60px);
}
.myevents-container .invite_card {
  background: linear-gradient(180deg, #ebebeb 0%, #ffde37 100%);
  height: fit-content;
  margin: 10px;
}
.myevents-container .invite_card h3 {
  font-size: 20px;
}
.myevents-container .invite_card p {
  color: #390b0d;
  padding: 5px;
  font-weight: 500;
  font-size: 14px;
}

.myevents-container .invite_card p span {
  font-weight: 600;
}

.members {
  display: flex;
  flex-direction: column;
}

h4 {
  color: #333;
}

.myevents-wrapper .invitation-display {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.myevents-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.member_show {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
