.payment_container {
  background: linear-gradient(180deg, #5d0809 5.9%, #200304 100%);
  min-height: calc(100vh - 62px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.payment_box {
  border: 2px solid #ffde4c;
  padding: 0px 20px 40px;
}

.payment_container .button,
.event_detail_container .button {
  background-color: #ffde4c;
  padding: 10px 30px;
  outline: none;
  border: 0px;
  cursor: pointer;
  box-shadow: 0px 2px 10px 4px rgba(0, 0, 0, 0.2);
  color: #5d0809;
  font-size: 16px;
  font-family: "Lato";
  font-weight: 600;
  margin: 5px 0px;
}
.payment_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  background-color: white;
}

.payment_container p {
  margin: 10px 0px 30px;
  color: #333;
  font-size: 16px;
}
.payment_container .button a {
  text-decoration: none;
  color: firebrick;
  font-size: 16px;
  font-family: "Lato";
  font-weight: 600;
}
.success_pay {
  font-weight: 600;
}
body {
  margin: 0;
  background: #020202;
}
canvas {
  display: flex;
}

@media (max-width: 700px) {
  .payment_container h1 {
    font-size: 24px;
  }
}
