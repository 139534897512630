#landing-container {
  /* background: linear-gradient(180deg, #5d0809 0%, #3d0a0b 40.18%, #0d0d0d 100%); */
  background: linear-gradient(180deg, #f3d465 0%, #8c1b1d 40.18%, #8c1b1d 100%);
  color: #d0b532;
  position: relative;
  overflow-x: hidden;
  /* background-attachment: fixed; */
}
.logo-icon {
  width: 640px;
  height: 380px;
  position: relative;
}
.logo-icon img {
  position: absolute;
  top: -50vh;
  left: -30px;
  width: 100%;
  height: 100%;
}
section {
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow-y: hidden;
}
section #gallery {
  min-height: 50vh;
}
section#about {
  overflow-y: hidden;
  overflow: hidden;
}
.boats {
  height: 1040px;
  width: 1000px;
  top: 0vh;
  left: 0px;
}
.boatrace {
  top: -25vh;
  height: 150vh;
  width: 90vw;
}
.bubble {
  top: -25vh;
  left: 0px;
  height: 500px;
  width: 320px;
}
.bubble2 {
  top: 55vh;
  right: -30px;
  height: 405px;
  width: 400px;
}
/* .design {
  top: 0vh;
  left: 0px;
  height: 520px;
  width: 500px;
} */
.spark {
  position: absolute;
  background: #d9d9d9;
  box-shadow: 4px 4px 250px 120px rgba(254, 215, 22, 0.584);
  width: 1px;
  height: 1px;
  top: 100px;
  left: 20px;
}
.spark2 {
  position: absolute;
  background: #d9d9d9;
  box-shadow: 4px 4px 250px 120px rgba(254, 215, 22, 0.677);
  width: 1px;
  height: 1px;
  top: 100vh;
  right: 100px;
}
.spark3 {
  position: absolute;
  background: #d9d9d9;
  box-shadow: 4px 4px 250px 120px rgba(248, 213, 40, 0.581);
  width: 1px;
  height: 1px;
  top: 200vh;
  left: 0px;
}
.spark4 {
  position: absolute;
  background: #d9d9d9;
  box-shadow: 4px 4px 250px 120px rgba(210, 248, 40, 0.726);
  width: 1px;
  height: 1px;
  top: 250vh;
  right: 0px;
}
#home {
  position: relative;
  /* background-size: cover;
  background-position: center; */
  height: 150vh;
  overflow-x: hidden;
}

section h1 {
  font-size: 40px;
  font-family: "Marcellus SC", sans-serif;
  letter-spacing: 1px;
  font-weight: 500;
}

/* .google-btn */
.google-btn {
  position: absolute;
  top: 35%;
  width: 200px;
  height: 42px;
  background-color: transparent;
  border-radius: 2px;
  box-shadow: 3px 3px 15px 5px rgba(255, 226, 3, 0.775);
  border: 0px;
  outline: 0px;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #d0b532;
  background: linear-gradient(91deg, #570809 0%, #79060a 104.9%);
  font-family: "Lato";
  transition: 0.25s;
}

/*popup*/
.popup_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.popup {
  background-color: white;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.144);
  border-radius: 10px;
  position: relative;
  width: fit-content;
}
.popup .i {
  color: #333;
  position: absolute;
  top: 10px;
  right: 10px;
}

.popup-text {
  color: black;
  padding: 20px;
  text-align: center;
}
.popup-list {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}
.popup-list p {
  color: rgb(228, 183, 0);
  padding: 0px 0px 8px;
}
.popup-list li {
  padding: 2px;
  font-size: 14px;
  color: #333;
  list-style-type: disc;
}
.button-section {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.button-section button {
  padding: 5px 15px;
  outline: 0;
  border: 0px;
  color: white;
  font-family: "Poppins";
  cursor: pointer;
}
.button-section button.accept {
  display: flex;
  background-color: #1776d5;
  flex-direction: row;
  /* position: relative; */
}
.button-section button.reject {
  background-color: #3e4043;
}

.simple-spinner {
  padding: 0px 10px;
  width: 15px;
  height: 15px;
  /* position: absolute; */
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
}

.simple-spinner span {
  display: block;
  width: 15px;
  height: 15px;
  border: 3px solid transparent;
  border-radius: 50%;
  border-right-color: rgba(255, 255, 255, 0.7);
  animation: spinner-anim 0.8s linear infinite;
}

@keyframes spinner-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
/* .google-icon-wrapper */
.google-btn .google-icon-wrapper {
  width: 40px;
  height: 40px;
  margin: 1px;
  border-radius: 2px;
}

/* .google-icon */
.google-btn .google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: -8px;
  width: 18px;
  height: 18px;
}

/* .btn-text */
.google-btn .btn-text {
  margin: 5px 11px 5px 0px;
  color: #e5ca46;
  font-size: 16px;
  letter-spacing: 0.2px;
  font-family: "Lato";
}

/* .google-btn:hover */
.google-btn:hover {
  box-shadow: 0 0 6px #d0b532;
}

/* .google-btn:active */
.google-btn:active {
  background: #4f090a;
}

.about-content {
  color: rgb(255, 255, 255);
  font-family: "Lato";
  width: 60%;
  text-align: center;
  line-height: 2;
  letter-spacing: 0.5px;
}
#about {
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;
  color: #ffffff;
}
#contact {
  min-height: fit-content;
}
@media (max-width: 800px) {
  .google-btn {
    top: 45%;
    width: 180px;
    height: 42px;
    box-shadow: 0px 0px 10px 2px rgba(255, 226, 3, 0.441);
  }
  .google-btn .btn-text {
    font-size: 13px;
  }
  .boatrace {
    top: -25vh;
    height: 120vh;
    width: 100vw;
  }

  section h1 {
    font-size: 30px;
  }
  .about-content {
    font-size: 14px;
    width: 80%;
    letter-spacing: 1px;
    line-height: 1.5;
  }
  #about {
    background-size: 80%;
  }
  .logo-icon {
    width: 400px;
    height: 220px;
    position: relative;
    top: 30vh;
  }
  .logo-icon img {
    left: 0px;
  }
  #home,
  #about {
    background-size: "contain";
    min-height: 100vh;
  }
  #home {
    min-height: 100vh;
    height: 100vh;
  }
  section {
    min-height: fit-content;
  }
  .boats {
    height: 600px;
    width: 600px;
    top: 20vh;
  }
  .bubble {
    top: -20vh;
    left: 0px;
    height: 320px;
    width: 200px;
  }
  .bubble2 {
    top: 55vh;
    right: -80px;
    height: 240px;
    width: 240px;
  }
}

@media (max-width: 500px) {
  .about-content {
    width: 90%;
    letter-spacing: 0.5px;
  }
  .logo-icon {
    width: 320px;
    height: 180px;
    position: relative;
  }
}
