.invitation-container {
  background: linear-gradient(180deg, #f3d465 0%, #b3292b 40.18%, #4f0a0b 100%);

  min-height: calc(100vh - 62px);
}
.invitation-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.invite_card {
  margin: 10px 20px;
  padding: 10px;
  box-sizing: border-box;
  height: 200px;
  width: 300px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background: linear-gradient(180deg, #fff 0%, #ffffff 100%);
  box-shadow: -5px 5px 40px 0px #b2dae5 inset;
}
.invitation-display {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.invite_card .category {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #e51b20;
  color: white;
  font-size: 14px;
  width: wrap-content;
  padding: 10px 15px;
  width: 120px;
  text-align: center;

  margin: 0 auto;
  margin-top: -15px;
}
.invite_card h3 {
  text-align: center;
  font-weight: 600;
  color: #323238;
  padding: 15px;
}
.invite_card .date_n_time {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.invite_card .date_n_time .detail {
  padding: 0px 10px;
}
.invite_card .detail {
  text-align: center;
  color: #676767;
  font-size: 14px;
}

.invite_card .btn {
  outline: none;
  border: 0px;
  background: #fff;
  width: calc(100% - 40px);
  margin: 10px 5px;
  padding: 10px 15px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
}
.invite_card .btn {
  color: #6a6a6a;
  font-weight: 600;
}

.buttons_element {
  display: flex;
  flex-direction: row;
}

.null_msg {
  font-size: 20px;
  color: white;
  justify-self: center;
  margin: 0 auto;
  border: 0.5px solid rgb(251, 251, 120);
  padding: 80px;
  box-shadow: 0px 2px 20px 10px rgba(0, 0, 0, 0.25);
}
