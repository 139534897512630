.carousel {
  display: flex;
  height: 400px;
  width: 100%;
  max-width: 1000px;
  font-family: "Marcellus SC";
  margin: 60px 0px;
}

.carousel_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel_card {
  display: flex;
  flex: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.15);
  opacity: 0;
  pointer-events: none;
  transform: scale(0.95);
  transition: 0.8s ease-in-out;
}

.carousel_card-active {
  opacity: 1;
  transform: scale(1);
  pointer-events: visible;
}

.card_image {
  width: 100%;
  object-fit: cover;
}

.card_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: column;
  padding: 20px 64px;
  align-items: flex-start;
}

.card_title {
  color: white;
  font-size: 30px;
  width: 60%;
  margin-top: 20px;
  letter-spacing: 1px;
}
.card_desc {
  font-size: 16px;
  font-family: "Lato";
  width: 60%;
  color: white;
  padding: 10px 0px;
}

.carousel_arrow_left,
.carousel_arrow_right {
  position: absolute;
  font-size: 40px;
  top: 50%;
  transform: translate(0, -50%);
  background-color: gainsboro;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding-bottom: 7px;
  cursor: pointer;
}

.carousel_arrow_left {
  left: 15px;
}

.carousel_arrow_right {
  right: 15px;
}

.carousel_pagination {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, 0);
}

.pagination_dot {
  height: 10px;
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.pagination_dot:hover {
  transform: scale(1.2);
}

.pagination_dot-active {
  background-color: rgb(142, 9, 9);
}
@media (max-width: 800px) {
  .card_title {
    margin-top: 10px;
    font-size: 24px;
    text-align: center;
    margin: 0 auto;
  }
  .card_desc {
    width: 100%;
    text-align: center;
    line-height: 1.2;
  }
  .carousel_arrow_left,
  .carousel_arrow_right {
    font-size: 20px;
    width: 20px;
    height: 20px;
  }
}
@media (max-width: 500px) {
  .carousel {
    height: 70vh;
  }
}
