.upi_container {
  background: linear-gradient(180deg, #5d0809 5.9%, #200304 100%);
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr_container .scan {
  color: white;
  font-family: "Lato";
  letter-spacing: 0.5px;
  font-size: 20px;
  text-align: center;
  margin: 0px 0px 40px;
}
.qr_container .info {
  color: rgb(255, 196, 0);
  font-family: "Lato";
  letter-spacing: 0.5px;
  font-size: 16px;
  text-align: center;
  margin: 20px 0px 0px;
}

.qr {
  height: 400px;
  width: 400px;
}

.qr-items {
  margin: 0 auto;
  font-weight: 500;
  letter-spacing: 1px;
  position: absolute;
}

.qr_nav_container {
  margin: -8px auto;
  max-width: 1100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm {
  outline: 0px;
  border: 0px;
  padding: 10px 15px;
  margin: 40px 20px;
  background: #ffc400;
  cursor: pointer;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.35);
}

.confirm a {
  font-family: "Lato";
  text-decoration: none;
  color: #5d0809;
  font-size: 16px;
  font-weight: 600;
}

.confirm:hover {
  color: #a71416;
  font-size: 16px;
  font-weight: 600;
  background: #ffd23e;
  box-shadow: 0px 0px 4px 2px rgba(255, 255, 255, 0.095);
}
