.dahsboard_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 60px);
}
.event_list {
  display: flex;
  justify-content: center; /* Start from the left side */
  align-items: center; /* Start from the top */

  margin: 30px 0px;
  width: 100vw;
  flex-wrap: wrap;
}

.dahsboard_container h2 {
  font-family: "Marcellus SC", sans-serif;
  color: #ffd500;
}
.profile {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px;
  font-family: "Marcellus SC";
  letter-spacing: 2px;
  font-weight: 400;
  padding: 0px 10px;
  box-shadow: 2px 2px 6px 3px rgba(0, 0, 0, 0.2);
}
.profile h4 {
  padding: 10px 20px;
  color: rgb(32, 32, 32);
  text-transform: uppercase;
  background-color: transparent;
  margin: 10px;
  box-shadow: 2px 2px 6px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  font-size: 14px;
}
.profile .i {
  height: 36px;
  width: 36px;
  color: #ffffff;
  background-color: #e82121;
  padding: 12px;
  font-size: 14px;
  border-radius: 32px;
}
hr {
  width: 90%;
  margin: 20px;
  color: #ffd500;
}

.loader {
  background: linear-gradient(180deg, #f3d465 0%, #b3292b 40.18%, #4f0a0b 100%);

  /* background: linear-gradient(180deg, #930c0e 0%, #3d0a0b 100%); */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.thanima_main {
  display: flex;
}
.queries {
  font-family: "Lato";
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000;
  color: #e5d494;
  font-size: 16px;
}

.queries .link {
  text-decoration: underline;
}
@media (max-width: 800px) {
  .event_detail_container {
    align-items: center;
    text-align: center;
  }
  .profile {
    /* background: white; */
    flex-direction: column;
    padding: 20px 10px;
  }
}
