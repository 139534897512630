.input-group {
  margin: 15px 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 60px;
  border-bottom: 1px solid #870909;
}
.input-group.short {
  margin: 15px 0px;
  flex-direction: column;
  justify-content: none;
  width: 60%;
  padding: 10px 0px;
  flex-wrap: wrap;
}
.input-group.short .inp-field {
  width: 80%;
}

.label {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.03em;
  margin-bottom: 5px;
}
.label span {
  color: transparent;
}

.label.mandatory span {
  color: #ff1437;
}

.label-group {
  display: flex;
  flex-direction: column;
  max-width: 36%;
}
.label.light {
  color: #333;
}
.label.dark {
  color: var(--subheader-color-dark);
}
.inp-desc {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: var(--description-color);
}
.inp-field {
  height: 42px;
  width: 50%;
  background-color: #ffffff;
  border: 0.5px solid #e2e2e2;
  outline: none;
  padding: 5px 10px;
  font-size: 14px;
}
.inp-field:hover {
  border: 0.5px solid #ffc20c;
  box-shadow: 0 2px 4px solid rgba(0, 0, 0, 0.2);
}
.text-field {
  min-height: 100px;
  width: 60%;
  background-color: #ffffff;
  border: 0.5px solid #e2e2e2;
  outline: none;
  padding: 5px 10px;
  font-size: 14px;
  font-family: "Open Sans";
  background-color: #f4f4f4;
}
.text-field:hover {
  border: 0.5px solid #000000;
  box-shadow: 0 2px 4px solid rgba(0, 0, 0, 0.2);
}
.text-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
.input-group.option {
  width: 100%;
  height: 40px;
  border-bottom: 0px;
  justify-content: flex-start;
  align-items: center;
}
.input-group.popup-inp {
  width: 100%;
  height: 40px;
  border-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.input-group.popup-inp label {
  font-size: 16px;
  margin-bottom: 15px;
}
.input-group.popup-inp input {
  width: 80%;
  margin: 0;
}

.option input {
  width: 14px;
  height: 14px;
  margin-left: 5px;
}

.dynamic-input-group {
  display: flex;
  flex-direction: column;
  width: 60%;
}
.dynamic-input-group .inp-field {
  width: 80%;
  margin: 5px 0px;
}
.dynamic-input-group button {
  width: 80%;
  margin: 10px 0px;
  cursor: pointer;
  text-align: right;
  padding: 10px 0px;
}
.dynamic-input-group button:hover {
  color: #7482f8;
}
